:root {
  color-scheme: dark;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #0d1117;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: calc(100vh - 44px);
}

.tabs {
  display: flex;
  font-size: 14px;
  background-color: #161b22;
  padding: 6px 8px;
  column-gap: 8px;
  overflow-x: auto;
}

.tab {
  padding: 6px 8px 6px 12px;
  cursor: default;
  display: flex;
  justify-content: space-between;
  color: #858d97;
  border-radius: 8px;
  align-items: baseline;
}

.tab:hover {
  background-color: #004a78;
  color: #ffffff;
}

.active {
  color: #ffffff;
  background-color: #3c3c3c;
}

.tabs > .active:hover {
  background-color: #3c3c3c;
}

.tabtext {
  overflow-x: hidden;
  white-space: nowrap;
  width: 50px;
}

.newtab {
  padding: 0;
  height: 32px;
  width: 32px;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
}

.delete {
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
}

.delete:hover {
  background-color: #34678d;
}

.save {
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  position: fixed;
  bottom: 0;
  height: 30px;
  width: calc(100% - 16px);
  background-color: #0d1117;
}

.raw {
  height: calc(100% - 24px);
  padding: 12px;
}

.markdown-container {
  max-width: 50vw;
  padding: 12px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  word-wrap: break-word;
  text-align: left;
  height: calc(100% - 24px);
}

.markdown {
  padding: 8px 8px 32px 8px;
  word-wrap: break-word;
  overflow-y: scroll;
  border-radius: 4px;
  text-align: left;
  height: calc(100% - 90px);
}

.markdown * {
  max-width: 50vw;
  overflow-wrap: break-word;
  font-size: 14px;
  color: #e6edf3;
  text-align: left;
}

.markdown ul {
  margin: 0;
}

.markdown h1 {
  font-size: 28px;
  border-bottom: 1px solid rgb(48, 54, 61);
  margin: 24px 0 16px 0;
  padding: 0 0 8px 0;
}

.markdown h2 {
  font-size: 21px;
  border-bottom: 1px solid rgb(48, 54, 61);
  padding: 0 0 6px 0;
}

.markdown h3 {
  font-size: 17px;
}

.markdown a {
  color: rgb(47, 129, 247);
}

.markdown code {
  background-color: rgb(22, 27, 34);
  padding: 16px;
  border-radius: 6px;
  overflow: auto;
  max-width: 100%;
  display: block;
  white-space: pre-wrap;
  min-height: 16px;
}

.markdown pre {
  word-wrap: break-word;
}

textarea {
  width: 100%;
  height: calc(100% - 50px);
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #0d1117;
  color: #e6edf3;
  font-size: 16px;
  resize: none;
  overflow-y: scroll;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
}

.save {
  background-color: transparent;
  padding: 16px 20px;
  width: calc(100% - 40px);
}

.save button {
  background-color: #238636;
  border: 1px solid #28923d;
  color: white;
  border-radius: 4px;
  padding: 0 12px;
  cursor: pointer;
  font-weight: 600;
}

.save button:hover {
  background-color: #2fa044;
}
